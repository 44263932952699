import ApiService from "../../../core/services/api.service";

const apiService = ApiService;

export default class BlogService {
  #api = null;

  constructor() {
    this.#api = `admin/blog`;
  }

  create(data) {
    let url = `${this.#api}`;
    return apiService.post(url, data);
  }

  paginate(data = {}, index = null) {
    let url = `${this.#api}`;
    if (index != null) url = `${url}?page=${index}`;
    let param = {
      params: data
    };
    return apiService.query(url, param);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.post(url, data);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }
}
